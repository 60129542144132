import './App.css';

function App() {
  return (
    <div className="App bg-neutral-100 min-h-screen dark:bg-neutral-800 dark:text-neutral-200">
      <header className='bg-white dark:bg-black shadow-md'>

        <div className='container max-w-md mx-auto center'>
          <nav className='mx-3'>
            <a className='menu-item' href="https://www.linkedin.com/in/arye-eidelman/">LinkedIn</a>
            <a className='menu-item' href="https://github.com/arye-eidelman">Github</a>
            <a className='menu-item' href="https://stackoverflow.com/users/3458162/arye-eidelman">StackOverflow</a>
          </nav>
        </div>
      </header>

      <div className='py-8 mx-auto max-w-md'>
        <section className='m-3 shadow-md rounded-lg bg-white dark:bg-black divide-y-2 divide-neutral-200 dark:divide-neutral-800'>
          <div className='p-3 font-code'>
            <h1 className='font-bold'>Arye Eidelman</h1>
            <a href="mailto:aryedoveidelman@gmail.com" className='a-fixed-padding'>
              {'<'}aryedov<span className='inline-block'>eidelman</span>@gmail.com{'>'}
            </a>
          </div>
          <p className='p-3'>
            Software Developer
          </p>
        </section>

        <section className='m-3 shadow-md rounded-lg bg-white dark:bg-black divide-y-2 divide-neutral-200 dark:divide-neutral-800'>
          <div className='p-3 font-code'>
            <h2 className='font-bold'>Projects</h2>
          </div>
          {[
            {
              title: "Mafteiach",
              webLink: "https://www.mafteiach.app/",
              installLink: "http://onelink.to/mftch-install",
              playStoreLink: "https://play.google.com/store/apps/details?id=app.mafteiach.app",
              AppStoreLink: "https://apps.apple.com/us/app/mafteiach/id1455806077",
              tools: ["Ruby on Rails", "Tachyons"]
            },
            {
              title: "Daily Rambam",
              webLink: "https://www.dailyrambam.org/",
              tools: ["Ruby on Rails", "Bootstrap 4"]
            },
            {
              title: "Chess game",
              webLink: "https://ae-chess.netlify.app/",
              githubLink: "https://github.com/arye-eidelman/chess",
              tools: ["React 17", "Tailwind 3", "Netlify"]
            },
            {
              title: "Snake game",
              webLink: "https://ae-snake.netlify.app/",
              githubLink: "https://github.com/arye-eidelman/snake",
              tools: ["React 17", "Tailwind 2", "Netlify"]
            },
          ].map(({ title, webLink, installLink, githubLink, tools }, i) => (
            <article key={i} className='p-3'>
              <h3 className='pb-0.5 text-lg'>{title}</h3>
              <p className='space-x-0'>
                {webLink && <><a href={webLink} className="a-fixed-padding">Web</a>{' '}</>}
                {installLink && <><a href={installLink} className="a-fixed-padding">Install</a>{' '}</>}
                {githubLink && <><a href={githubLink} className="a-fixed-padding">GitHub</a>{' '}</>}
              </p>
              <p className='space-x-2'>
                {tools.map(tool =>
                  <span
                    key={tool}
                    className='inline-block rounded-full text-xs font-semibold text-neutral-700 dark:text-neutral-400'
                  >
                    {tool}
                  </span>)}
              </p>
            </article>
          ))}
          {/* <article><p className='p-3'>Mafteiach</p></article>
              <p className='p-3'>Daily Rambam</p>
              <p className='p-3'>Chess Game</p>
              <p className='p-3'>Mafteiach</p>
              */}
        </section>
      </div>
    </div>
  );
}

export default App;
